import React, { useContext, useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Layout from './Component/UI/Layout';
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import ForgotPassword from './Pages/Login/ForgotPassword';
import LoginContext from './Context/Login/LoginContext';
import DoctorList from './Pages/DoctorList/DoctorList';
import Product from './Pages/Product/Product';
import UserDashboard from './Pages/Admin/Dashboard/UserDashboard';
import PatientDashboard from './Pages/Admin/Dashboard/PatientDashboard';
import KamDashboard from './Pages/Admin/Dashboard/KamDashboard';
import ProductDashboard from './Pages/Admin/Dashboard/ProductDashboard';
import Test from './Pages/Admin/Dashboard/Test';
import BrandWisePatients from './Pages/Admin/Dashboard/BrandWisePatients';



function App() {

  let LoginLayoutPage;

  if (localStorage.getItem('userData')) {
    LoginLayoutPage = Layout;
  } else {
    LoginLayoutPage = Login;
  }


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginLayoutPage />}>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route index element={<Home />} />
          <Route path='/doctor-list' element={<DoctorList />} />
          <Route path='/product/:id/:actionName' element={<Product />} />
          <Route path='/user-dashboard' element={<UserDashboard />} />
          <Route path='/patient-dashboard' element={<PatientDashboard />} />
          <Route path='/kam-dashboard' element={<KamDashboard />} />
          <Route path='/product-dashboard' element={<ProductDashboard />} />
          <Route path='/brand-wise-patients' element={<BrandWisePatients />} />
          <Route path='/test' element={<Test />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )



}

export default App;
