import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import './BrandWisePatientPieChart.css';
import { Brand, Zone, ChartBackground } from '../../Service/AppConstants';

const BrandWisePatientPieChart = ({data, onClick}) => {

    const series = [data[0]['TotalPatients'], data[1]['TotalPatients'], data[2]['TotalPatients'], data[3]['TotalPatients']];
    const labels = [Brand.Oncyclo, Brand.Revugam, Brand.Revugam25, Brand.Thymogam];

    const options = {
        chart: {
            zoom: {
                enabled: false
            },
            events: {
                click: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
                    // console.clear();
                    // console.log('Events:', event);
                    // console.log('chartContext:', chartContext);
                    // console.log('seriesIndex:', seriesIndex);
                    // console.log('dataPointIndex:', dataPointIndex);
                    // console.log('config:', config);
                    // const selectedValue = config.series[seriesIndex][dataPointIndex];
                    // console.log("config:", config);
                    // console.log("config:", seriesIndex);
                    // console.log("selectedValue:", config.series[dataPointIndex]);

                    if (dataPointIndex !== -1) {
                        const selectedLabel = labels[dataPointIndex];     
                        const selectedValue = config.series[dataPointIndex];                    
                        onClick({selectedBrand: data.find(item => item.MedicineName === selectedLabel)});
                    } else {
                        console.log('No data point clicked.');
                    }
                }
            },
            background: ChartBackground,
            dropShadow: {
                enabled: true
            }
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 10,
            offsetY: -5,
            labels: {
                colors: '#fff',
                useSeriesColors: false
            },
            fontSize: '12px',
            fontWeight: 400,
            markers: {
                // fillColors: ['red', 'blue', 'yellow'] // Optionally change marker colors if needed
            }
        },
        labels: labels,
        colors: ["#ed1c24", "#16479e", "#00a651", "#00aeef"],
        title: {
            text: 'Brand Wise Patients',
            align: 'center',
            style: { fontWeight: 500, color: '#fff', fontSize: 12, marginBottom: '15px' },
            offsetY: 15
        },
        xaxis: {
            labels: {
                style: {
                    colors: '#fff', // Change label color
                    fontSize: '14px' // Change label font size
                }
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        plotOptions: {
            pie: {
                startAngle: 0,
                offsetX: 0,
                offsetY: 15,
            }
        }
    };

    return (
        <>
            <div id="brandWisePatientChart">
                <ReactApexChart options={options} series={series} type={'pie'} width={'100%'} height={418} />
            </div>
        </>
    );
};

export default React.memo(BrandWisePatientPieChart);