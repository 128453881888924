import React from 'react';
import './AdminDashboard.css';
import BrandWisePatientsComp from '../../../Component/BrandWisePatientsComp/BrandWisePatientsComp';

const BrandWisePatients = () => {
    return (
        <>
            <div className='user-dashboard-wrapper'>
                <h1>Brand Wise Patients</h1>
                <div className="p-3 pb-4">                    
                    <BrandWisePatientsComp />
                </div>
            </div>
        </>
    )
};

export default BrandWisePatients;