import React, { useState, useEffect } from 'react';
import './AdminDashboard.css';
import axios from "axios";
import configData from '../../../Config/Config.json';
import { formatDate, getZoneClass } from '../../../Service/Common';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { useLocation, useNavigate } from 'react-router-dom';


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const KamDashboard = () => {

    const url = `${configData.REPORT_URL}/Reports/EmpSummary`;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState([]);
    const query = useQuery();
    const zoneName = query.get('zoneName');
    const zoneId = query.get('zoneId');
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {

            const paramObj = {
                // Startdate: formatDate(startDate),
                // Enddate: formatDate(endDate),
                "ZoneID": zoneId
            };

            const resp = await axios.post(url, paramObj);
            if (resp.status === 200) {
                setData(resp.data.Data);
            }
        };

        fetchData();
    }, []);




    return (
        <div className='kam-main-content'>

            <div className='flex align-content-center same-detail-header'>
                <div className="back-btn flex align-items-center">
                    <img alt="logo" src={process.env.PUBLIC_URL + '/back-button.png'} onClick={() => navigate('/user-dashboard')} />
                </div>
                <div className={`
                    box-shadow patients-dr-wrapper patient-header align-content-center 
                    ${zoneName === 'EAST' && 'east-color-header'} 
                    ${zoneName === 'WEST' && 'west-color-header'}
                    ${zoneName === 'NORTH' && 'north-color-header'}
                    ${zoneName === 'SOUTH' && 'south-color-header'}
                    `}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <span className={`${getZoneClass(zoneId)}`}>{zoneName}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='kam-dashboard-wrapper'>

                {data.length === 0 && <Skeleton height="20rem" animation='wave' shape='rectangle' className='kam-dashboard-table-skelton'></Skeleton>}
                {data.length > 0 &&
                    <div className={`
                        table-data flex align-items-start justify-content-center 
                        ${zoneName === 'EAST' && 'east-table'} 
                        ${zoneName === 'WEST' && 'west-table'}
                        ${zoneName === 'NORTH' && 'north-table'}
                        ${zoneName === 'SOUTH' && 'south-table'}
                        `}>
                        <DataTable value={data} tableStyle={{ minWidth: '50rem' }}>
                            <Column field="employeename" header="Kam"></Column>
                            <Column field="TotalPatients" header="Patients"></Column>
                            <Column field="TotalVials" header="Vials"></Column>
                            <Column field="TotalPapValue" header="Pap"></Column>
                            <Column field="Totalstrips" header="Strips"></Column>
                        </DataTable>
                    </div>
                }
            </div>
        </div>
    )
};

export default KamDashboard;