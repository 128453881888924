import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ZonePerformanceByBrandItem = ({ brandName, east, west, south, north, zoneName, zoneId }) => {

    const navigate = useNavigate();

    return (
        <div className="col-3">
            <div className={`top-performer-wrapper product-performer-zone-wise ${brandName.toLowerCase()}`}>
                <b className={`heading ${brandName.toLowerCase()}`}>{brandName.toLowerCase()}</b>
                <table className={`${brandName.toLowerCase()}`}>
                    <tbody>
                        <tr onClick={() => navigate(`/product-dashboard?zoneId=${1002}&brandName=${brandName.toLowerCase()}&zoneName=North`)}>
                            <td>North</td>
                            <td>{north}</td>
                        </tr>
                        <tr onClick={() => navigate(`/product-dashboard?zoneId=${1001}&brandName=${brandName.toLowerCase()}&zoneName=East`)}>
                            <td>East</td>
                            <td>{east}</td>
                        </tr>
                        <tr onClick={() => navigate(`/product-dashboard?zoneId=${1003}&brandName=${brandName.toLowerCase()}&zoneName=South`)}>
                            <td>South</td>
                            <td>{south}</td>
                        </tr>
                        <tr onClick={() => navigate(`/product-dashboard?zoneId=${1000}&brandName=${brandName.toLowerCase()}&zoneName=West`)}>
                            <td>West</td>
                            <td>{west}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default React.memo(ZonePerformanceByBrandItem);
