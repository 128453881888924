import React from 'react';
import './AdminDashboard.css';
import { getMonthName } from '../../../Service/Common';

const monthData = [
    {
        "OrderYear": 2024,
        "OrderMonth": 7,
        "OrderMonthName": "July",
        "DoctorsId": null,
        "DoctorsName": "ADITI MITTAL",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 0,
        "MedicineCount": 30,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": null,
        "DoctorsName": "Ankit Agarwal",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 0,
        "MedicineCount": 34,
        "TotalPapValue": 6,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": null,
        "DoctorsName": "Ankit Agarwal",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 0,
        "MedicineCount": 27,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": null,
        "DoctorsName": "GOVIND PATEL",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 0,
        "MedicineCount": 34,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": null,
        "DoctorsName": "GOVIND PATEL",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 0,
        "MedicineCount": 26,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "",
        "DoctorsName": "Alok Hemal",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 100,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 5
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "",
        "DoctorsName": "Atarvi paweja",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 40,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "",
        "DoctorsName": "Mukesh Dhanker",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 40,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 3
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "",
        "DoctorsName": "Mukesh Dhanker",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 11,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "",
        "DoctorsName": "Nishant Verma",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 25,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "",
        "DoctorsName": "Nishant Verma",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 15,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "",
        "DoctorsName": "Tejaswini Vaid",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 22,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "---",
        "DoctorsName": "Akash Khandelwal",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 38,
        "TotalPapValue": 7,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "----",
        "DoctorsName": "Dr Aditi Jain",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 80,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 3
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "----",
        "DoctorsName": "Dr Ankur Jain",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 80,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 3
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "----",
        "DoctorsName": "Dr Jatinder Arora",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 40,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "----",
        "DoctorsName": "Dr Nita Radhakrishnan",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 20,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "----",
        "DoctorsName": "Dr Nitin Sood",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 146,
        "TotalPapValue": 3,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 4
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "----",
        "DoctorsName": "Dr Nitin Sood",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 30,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "----",
        "DoctorsName": "Dr Nivedita Dhingra",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 18,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "----",
        "DoctorsName": "Dr Sanghamitra",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 55,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "----",
        "DoctorsName": "Dr Sumant Gupta",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 35,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "----",
        "DoctorsName": "Dr Sumant Gupta",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 52,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "----",
        "DoctorsName": "Dr Sumant Gupta",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 14,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "----",
        "DoctorsName": "Dr Sumit",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 70,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 3
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "----",
        "DoctorsName": "Dr Sumita Chauhdhary",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 95,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 3
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "----",
        "DoctorsName": "Dr. Kartikey",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 59,
        "TotalPapValue": 4,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 12,
        "OrderMonthName": "December",
        "DoctorsId": "----",
        "DoctorsName": "Dr. Pravas Chandra Mishra",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 36,
        "TotalPapValue": 4,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "----",
        "DoctorsName": "Dr. Pravas Chandra Mishra",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 10,
        "TotalPapValue": 4,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "----",
        "DoctorsName": "Dr. Saurabh",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 39,
        "TotalPapValue": 8,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "----",
        "DoctorsName": "Dr. Shilpa Khanna",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 100,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 5
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 12,
        "OrderMonthName": "December",
        "DoctorsId": "   3720244",
        "DoctorsName": "Dr Nivedita Dhingra",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 20,
        "TotalPapValue": 2,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "   3720254",
        "DoctorsName": "RISHI DHAWAN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 72,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "   3720255",
        "DoctorsName": "PRADEEP KUMAR ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 88,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 3
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "   3720257",
        "DoctorsName": "MUKUL AGGARWAL ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 36,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "   3720385",
        "DoctorsName": "Priyanka Agrawal",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 50,
        "TotalPapValue": 5,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "   3720386",
        "DoctorsName": "MONA V KHAN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 38,
        "TotalPapValue": 4,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "   3720392",
        "DoctorsName": "PRIYANKA VERMA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 47,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "   3720394",
        "DoctorsName": "Deepankar Bhattacharya ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 10,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "   3720425",
        "DoctorsName": "ARIHANT JAIN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 34,
        "TotalPapValue": 2,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "   3720429",
        "DoctorsName": "DEEPAK BANSAL ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 10,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "   3720435",
        "DoctorsName": "APARNA",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 16,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "123411",
        "DoctorsName": "Sumita",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 150,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 6
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "152",
        "DoctorsName": "Dr Rahul Nathani",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 35,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "160",
        "DoctorsName": "Dr. Priyanshi Pachouri",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 32,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "1760707",
        "DoctorsName": "S P VERMA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 135,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 4
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "1760707",
        "DoctorsName": "S P VERMA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 171,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 6
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 7,
        "OrderMonthName": "July",
        "DoctorsId": "1760924",
        "DoctorsName": "ASHISH VERMA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 60,
        "TotalPapValue": 10,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "1761116",
        "DoctorsName": "PRIYANKA VERMA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 50,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "1761116",
        "DoctorsName": "PRIYANKA VERMA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 62,
        "TotalPapValue": 2,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 3
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "1763011",
        "DoctorsName": "JOSEPH JOHN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 25,
        "TotalPapValue": 2,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "1763011",
        "DoctorsName": "JOSEPH JOHN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 25,
        "TotalPapValue": 4,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "1765004",
        "DoctorsName": "Deepankar Bhattacharya ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 26,
        "TotalPapValue": 2,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "1767164",
        "DoctorsName": "ANSHUL GUPTA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 10,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 7,
        "OrderMonthName": "July",
        "DoctorsId": "1767166",
        "DoctorsName": "SANJEEV KUMAR ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 103,
        "TotalPapValue": 5,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 3
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "1767174",
        "DoctorsName": "MONA V KHAN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 95,
        "TotalPapValue": 10,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 7,
        "OrderMonthName": "July",
        "DoctorsId": "1767174",
        "DoctorsName": "MONA V KHAN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 96,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 4
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "1767174",
        "DoctorsName": "MONA V KHAN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 38,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "2278297",
        "DoctorsName": "ROHAN HALDER ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 46,
        "TotalPapValue": 5,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "2278297",
        "DoctorsName": "ROHAN HALDER ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 44,
        "TotalPapValue": 4,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "2278302",
        "DoctorsName": "RAYAZ AHMED ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 50,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "2278302",
        "DoctorsName": "RAYAZ AHMED ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 18,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "2278304",
        "DoctorsName": "SANDIP JAIN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 14,
        "TotalPapValue": 2,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "2278381",
        "DoctorsName": "GAURAV DIXIT ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 45,
        "TotalPapValue": 5,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "2281187",
        "DoctorsName": "UPENDRA SHARMA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 34,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "2281559",
        "DoctorsName": "PRIYANKA SONI ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 25,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "2281559",
        "DoctorsName": "PRIYANKA SONI ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 20,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "231",
        "DoctorsName": "Priyanka Agrawal",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 9,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "2395000",
        "DoctorsName": "TARUN VERMA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 40,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "2395000",
        "DoctorsName": "TARUN VERMA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 38,
        "TotalPapValue": 6,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "2395000",
        "DoctorsName": "TARUN VERMA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 76,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "2453520",
        "DoctorsName": "AMITABH SINGH ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 75,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 3
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "2453523",
        "DoctorsName": "ANKUR JAIN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 100,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 4
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "2455790",
        "DoctorsName": "ALKA KHADWAL ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 9,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "2455790",
        "DoctorsName": "ALKA KHADWAL ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 17,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "2455791",
        "DoctorsName": "ARIHANT JAIN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 32,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "2455791",
        "DoctorsName": "ARIHANT JAIN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 24,
        "TotalPapValue": 3,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "2455791",
        "DoctorsName": "ARIHANT JAIN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 30,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "2455791",
        "DoctorsName": "ARIHANT JAIN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 41,
        "TotalPapValue": 3,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "2455791",
        "DoctorsName": "ARIHANT JAIN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 25,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 7,
        "OrderMonthName": "July",
        "DoctorsId": "2455792",
        "DoctorsName": "PANKAJ MALHOTRA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 26,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "2455792",
        "DoctorsName": "PANKAJ MALHOTRA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 30,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "2455792",
        "DoctorsName": "PANKAJ MALHOTRA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 30,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "2455792",
        "DoctorsName": "PANKAJ MALHOTRA ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 19,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "2455836",
        "DoctorsName": "DEEPAK BANSAL ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 6,
        "TotalPapValue": 1,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 7,
        "OrderMonthName": "July",
        "DoctorsId": "2455836",
        "DoctorsName": "DEEPAK BANSAL ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 22,
        "TotalPapValue": 3,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "2455836",
        "DoctorsName": "DEEPAK BANSAL ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 16,
        "TotalPapValue": 2,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "2455837",
        "DoctorsName": "AMITA TREHAN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 19,
        "TotalPapValue": 2,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "2745071",
        "DoctorsName": "RISHI DHAWAN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 23,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 7,
        "OrderMonthName": "July",
        "DoctorsId": "2745071",
        "DoctorsName": "RISHI DHAWAN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 20,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "2745071",
        "DoctorsName": "RISHI DHAWAN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 40,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "2745071",
        "DoctorsName": "RISHI DHAWAN ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 55,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "2745072",
        "DoctorsName": "PRADEEP KUMAR ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 135,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 4
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "2745072",
        "DoctorsName": "PRADEEP KUMAR ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 58,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "2745072",
        "DoctorsName": "PRADEEP KUMAR ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 43,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "2745074",
        "DoctorsName": "MUKUL AGGARWAL ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 27,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "CSV102",
        "DoctorsName": "MEET KUMAR",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 69,
        "TotalPapValue": 5,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "CSV102",
        "DoctorsName": "MEET KUMAR",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 25,
        "TotalPapValue": 5,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "CSV107",
        "DoctorsName": "ARUN DEWANA",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 15,
        "TotalPapValue": 3,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "CSV107",
        "DoctorsName": "ARUN DEWANA",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 10,
        "TotalPapValue": 2,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "CSV107",
        "DoctorsName": "ARUN DEWANA",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 20,
        "TotalPapValue": 2,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "CSV107",
        "DoctorsName": "ARUN DEWANA",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 10,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "CSV109",
        "DoctorsName": "DINESH PENDARKAR",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 20,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "CSV109",
        "DoctorsName": "DINESH PENDARKAR",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 32,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "CSV118",
        "DoctorsName": "Yatendra Parashar",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 25,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "CSV118",
        "DoctorsName": "Yatendra Parashar",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 23,
        "TotalPapValue": 2,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "CSV119",
        "DoctorsName": "Shilpi Agrawal",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 25,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "CSV142",
        "DoctorsName": "Amiya",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 21,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "CSV142",
        "DoctorsName": "Amiya",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 38,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "CSV146",
        "DoctorsName": "Dr Neelabh",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 20,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "CSV146",
        "DoctorsName": "Dr Neelabh",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 26,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "CSV149",
        "DoctorsName": "Homdutt ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 25,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "CSV153",
        "DoctorsName": "Mehak",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 40,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "CSV159",
        "DoctorsName": "Rachit",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 22,
        "TotalPapValue": 7,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 11,
        "OrderMonthName": "November",
        "DoctorsId": "CSV160",
        "DoctorsName": "Rahul bhargava ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 45,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "CSV160",
        "DoctorsName": "Rahul bhargava ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 29,
        "TotalPapValue": 3,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 8,
        "OrderMonthName": "August",
        "DoctorsId": "CSV160",
        "DoctorsName": "Rahul bhargava ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 36,
        "TotalPapValue": 3,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "CSV160",
        "DoctorsName": "Rahul bhargava ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 40,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "CSV165",
        "DoctorsName": "Swapnil ",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 64,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "CSV66",
        "DoctorsName": "Payal Malhotra",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 20,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 10,
        "OrderMonthName": "October",
        "DoctorsId": "CSV85",
        "DoctorsName": "Uttam Singh Nath",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 35,
        "TotalPapValue": 3,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "CSV85",
        "DoctorsName": "Uttam Singh Nath",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 40,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "CSV85",
        "DoctorsName": "Uttam Singh Nath",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 29,
        "TotalPapValue": 6,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "CSV85",
        "DoctorsName": "Uttam Singh Nath",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 30,
        "TotalPapValue": 5,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "-NA-",
        "DoctorsName": "Dr Alka Yadav",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 78,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 5
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 5,
        "OrderMonthName": "May",
        "DoctorsId": "-NA-",
        "DoctorsName": "Dr Alka Yadav",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 60,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 4
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "-NA-",
        "DoctorsName": "Dr Alka Yadav",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 25,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 6,
        "OrderMonthName": "June",
        "DoctorsId": "-NA-",
        "DoctorsName": "Dr Nitin Gupta",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 60,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 2
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "-NA-",
        "DoctorsName": "Dr Nitin Gupta",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 35,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 9,
        "OrderMonthName": "September",
        "DoctorsId": "-NA-",
        "DoctorsName": "Dr SP Yadav",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 10,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    },
    {
        "OrderYear": 2024,
        "OrderMonth": 4,
        "OrderMonthName": "April",
        "DoctorsId": "-NA-",
        "DoctorsName": "Dr SP Yadav",
        "ZoneId": 1002,
        "ZoneName": "NORTH",
        "UniqueDoctors": 1,
        "MedicineCount": 16,
        "TotalPapValue": 0,
        "MedID": 37,
        "MedicineName": "Thymogam",
        "TotalPatients": 1
    }
];

const Test = () => {

    const groupedData = monthData.reduce((acc, curr) => {
        const monthName = curr.OrderMonthName;
        if (!acc[monthName]) {
          acc[monthName] = [];
        }
        acc[monthName].push(curr);
        return acc;
      }, {});

    return (
        <>
            {Object.keys(groupedData).map((month) => (
                <div key={month}>
                    <h3>{month}</h3>
                    <table border="1" style={{ marginBottom: '20px' }}>
                        <thead>
                            <tr>
                                <th>Doctors Name</th>
                                <th>Zone Name</th>
                                <th>Medicine Name</th>
                                <th>Medicine Count</th>
                                <th>Total Patients</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupedData[month].map((item, index) => (
                                <tr key={index}>
                                    <td>{item.DoctorsName}</td>
                                    <td>{item.ZoneName}</td>
                                    <td>{item.MedicineName}</td>
                                    <td>{item.MedicineCount}</td>
                                    <td>{item.TotalPatients}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </>
    )
};

export default Test;