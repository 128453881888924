import axios from "axios";
import configData from '../Config/Config.json';

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const getUserData = () => {
    if (localStorage.getItem('userData')) {
        return JSON.parse(localStorage.getItem('userData'));
    } else {
        return {};
    }
}



export const getImage = (path, fileName) => {
    return require(`${path + fileName.toLowerCase()}`);
}

export const getEmpId = () => {

    if (localStorage.getItem('userData')) {
        let EmpID = JSON.parse(localStorage.getItem('userData')).EmpID;
        if (EmpID) {
            return EmpID
        } else {
            return null;
        }
    }
};

export const getEmpName = () => {

    if (localStorage.getItem('userData')) {
        let name = JSON.parse(localStorage.getItem('userData')).firstName;
        if (name) {
            return name
        } else {
            return null;
        }
    }
};

export const groupByKey = (array, key) => {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
    }, {});
}

// export const sortAdminDataByDate = (array) => {
//     if (array.length > 0) {
//         array.sort((a, b) => {
//             let dateA = new Date(a.CreatedDate);
//             let dateB = new Date(b.CreatedDate);
//             return dateA - dateB;
//         });
//     }
// };

export const getIndicationText = (itemObj) => {
    if (itemObj === 'tdr') {
        return "Tdr";
    }
    else if (itemObj === 'aa') {
        return 'Aplastic Anaemia';
    } else if (itemObj === 'itp') {
        return 'Immune Thrombocytopenic Purpura';
    } else {
        return '-NA-';
    }
}

export function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function generateColorArray(count, color) {
    const colors = [];
    for (let i = 0; i < count; i++) {
        colors.push(color);
    }
    return colors;
}

export function checkUrlAndRunCommand(url, specificUrl) {
    if (url.includes(specificUrl)) {
        return true
    } else {
        return false;
    }
}

export const getPanIndiaData = async (url, setState) => {
    try {
        const response = await axios.post(url);
        if (response.status !== 200) {
            throw new Error('Failed to fetch data');
        }
        setState(response.data.Data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const getTopPerformingKamData = async (url, setState) => {
    try {
        const response = await axios.post(url);
        if (response.status !== 200) {
            throw new Error('Failed to fetch data');
        }
        setState(response.data.Data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const getKamSelectedByZoneYear = async (url, payload, setState) => {
    try {
        const response = await axios.post(url, payload);
        if (response.status !== 200) {
            throw new Error('Failed to fetch data');
        }
        setState(response.data.Data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const getBrandWisePatients = async (url, payload, setState) => {
    try {
        const response = await axios.post(url, payload);
        if (response.status !== 200) {
            throw new Error('Failed to fetch data');
        }
        setState(response.data.Data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const getBrandPatientsFiscalYear = async (url, payload) => {
    try {
        const response = await axios.post(url, payload);
        if (response.status !== 200) {
            throw new Error('Failed to fetch data');
        }        
        return response.data.Data
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const getBrandZoneFiscalSummary = async (url, payload, setState) => {
    try {
        const response = await axios.post(url, payload);
        if (response.status !== 200) {
            throw new Error('Failed to fetch data');
        }        
        setState(response.data.Data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


export const getBrandZoneFiscalSummaryMonthOnMonth = async (url, payload) => {
    try {
        const response = await axios.post(url, payload);
        if (response.status !== 200) {
            throw new Error('Failed to fetch data');
        }        
        return response.data.Data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const getBrandDoctorWiseFiscalSummary = async (url, payload) => {
    try {
        const response = await axios.post(url, payload);
        if (response.status !== 200) {
            throw new Error('Failed to fetch data');
        }        
        return response.data.Data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


export const getKamDetailByZoneStartEndDate = async (url, payload) => {
    try {
        const response = await axios.post(url, payload);
        if (response.status !== 200) {
            throw new Error('Failed to fetch data');
        }
        return response.data.Data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const getBrandPerfomance = async (url, setState) => {
    try {
        const response = await axios.post(url);
        if (response.status !== 200) {
            throw new Error('Failed to fetch data');
        }
        setState(response.data.Data);
        console.log(response.data.Data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const getMedicineUsageReport = async (url, startDate, endDate) => {
    try {
        const payload = {
            StartDate: formatDate(startDate),
            EndDate: formatDate(endDate)
        };

        const response = await axios.post(url, payload);

        if (response.status !== 200) {
            throw new Error('Failed to fetch data');
        }

        return response.data.Data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};




export const extractPropertyValues = (dataArray, propertyName) => {
    return dataArray.map(item => item[propertyName]);
};


export const getColorByZone = (zoneName) => {

    const zoneColors = {
        EAST: '#9b258f',
        WEST: '#f37021',
        NORTH: '#5c2d91',
        SOUTH: '#ec008c'
    };

    return zoneColors[zoneName.toUpperCase()] || '#000000'; // Default to black if zone is not found
}


export const getZoneClass = (zoneId) => {
    console.log(zoneId);
    switch (parseInt(zoneId)) {
        case 1000:
            return 'west-zone-text';
        case 1001:
            return 'east-zone-text';
        case 1002:
            return 'north-zone-text';
        case 1003:
            return 'south-zone-text';
        default:
            return '';
    }
}

export const getFirstLastDateOfTheYear = (year) => {
    const validYear = parseInt(year, 10);

    if (isNaN(validYear) || validYear <= 0) {
        throw new Error('Invalid year');
    }

    const firstDayOfYear = new Date(validYear, 0, 1); // January 1st
    const lastDayOfYear = new Date(validYear, 11, 31); // December 31st

    return { firstDayOfYear, lastDayOfYear };
}

export const removeTitleText = (title, textToRemove) => {
    if (title) {
        const outputTitle = title.replace(textToRemove, '').trim().toLowerCase();
        switch (outputTitle) {
            case 'east':
                return 1001;
            case 'north':
                return 1002;
            case 'south':
                return 1003;
            case 'west':
                return 1000;
            default:
                return 0;
        }
    }
}


export const getSelectedZone = (zoneId) => {
    if (zoneId) {
        switch (zoneId) {
            case 1000:
                return 'West';
            case 1001:
                return 'East';
            case 1002:
                return 'North';
            case 1003:
                return 'South';
            default:
                return '';
        }
    }
}

export const getLastDayOfMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
}


export const getMonthName = (monthIndex) => {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return monthNames[monthIndex];
};


export const getMonthlyDateRanges = (paramObj) => {
    
    const startYear = new Date(paramObj.StartDate).getFullYear();
    let result = [];

    for (let month = 0; month < 12; month++) {
        const startDate = formatDate(new Date(startYear, month, 1));
        const endDate = formatDate(new Date(startYear, month, getLastDayOfMonth(startYear, month)));

        result.push({
            "StartDate": startDate,
            "EndDate": endDate,
            "MonthName": getMonthName(month)
        });
    }

    return result;
}


export const getCurrentFinancialYearRange = () => {    
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-indexed, so January is 0.
  
    let startYear;
  
    // Assuming the financial year starts on April 1
    if (month >= 4) {
      startYear = year;
    } else {
      startYear = year - 1;
    }
  
    const startDate = new Date(startYear, 3, 1); // April 1 of the financial year
    const endDate = today; // Current date as the end date
  
    // Format the dates as "YYYY-MM-DD"
    const formatDate = (date) => 
      `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  
    return {
      StartDate: formatDate(startDate),
      EndDate: formatDate(endDate),
    };
  }


  export const getCurrentFinancialYearData = (data) => {    
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
  
    // Calculate start and end dates for the financial year
    let startYear, endYear;
    if (currentMonth >= 4) {
      startYear = currentYear;
      endYear = currentYear + 1;
    } else {
      startYear = currentYear - 1;
      endYear = currentYear;
    }
  
    const financialYearStart = new Date(startYear, 3, 1); // April 1 of the start year
    //const financialYearEnd = new Date(endYear, 2, 31); // March 31 of the end year
    const financialYearEnd = new Date(currentYear, today.getMonth(), new Date(currentYear, today.getMonth() + 1, 0).getDate()); // Last day of the current month
  
    // Filter data based on the current financial year range
    return data.filter(item => {
      const itemStartDate = new Date(item.StartDate);
      return itemStartDate >= financialYearStart && itemStartDate <= financialYearEnd;
    });
  }

  export const getKamDataBasedOnTheMonthZoneWise = async (objDateRange, ZoneId) => {
    const responses = [];

    for (const dateRange of objDateRange) {
        // Prepare parameters for each range
        const paramObj = {
            ZoneId: ZoneId,
            StartDate: dateRange.StartDate,
            EndDate: dateRange.EndDate
        };
        
        try {
            // Fetch data for the current date range
            const response = await getKamDetailByZoneStartEndDate(`${configData.REPORT_URL}/Reports/EmpSummary`, paramObj);
            
            // Push the response along with the MonthName to keep track
            responses.push({ MonthName: dateRange.MonthName, data: response });
        } catch (error) {
            console.error(`Error fetching data for ${dateRange.MonthName}:`, error);
        }
    }

    return responses;
}
  

export function parseFiscalYear(fiscalYear) {    
    // Regular expression to validate the fiscal year format (e.g., "2024-2025")
    const fiscalYearRegex = /^\d{4}-\d{4}$/;
  
    // Validate the input format
    if (!fiscalYearRegex.test(fiscalYear)) {
      throw new Error("Invalid fiscal year format. Expected format: 'YYYY-YYYY'");
    }
  
    // Split the input into startYear and endYear
    const [startYearStr, endYearStr] = fiscalYear.split("-");
  
    // Convert to numbers
    const startYear = parseInt(startYearStr, 10);
    const endYear = parseInt(endYearStr, 10);
  
    // Additional validation: Ensure startYear is less than endYear and years are consecutive
    if (endYear !== startYear + 1) {
      throw new Error("Invalid fiscal year range. The second year must be exactly one year after the first.");
    }
  
    return [startYear, endYear];
  }

  export function sortByPropertyName(data, property) {
    // Define the correct order for month names
    const monthOrder = ["January", "February", "March", "April", "May","June", "July", "August", "September","October", "November", "December"];
  
    // Check if the property exists in all objects and sort by it
    return data.sort((a, b) => {
      const monthA = monthOrder.indexOf(a[property]);
      const monthB = monthOrder.indexOf(b[property]);
  
      // If either month is not found, treat it as less important
      if (monthA === -1 || monthB === -1) {
        return monthA - monthB;
      }

      return monthA - monthB;
    });
  }

// const data = [/* The provided data array */];
// const sortedData = sortByPropertyName(data, "OrderMonthName");
// console.log(sortedData);

export function groupByProperty(data, property) {
    return data.reduce((acc, item) => {
      const key = item[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  }

  export const findMaxLengthProperty = (groupedData) => {
    const maxProperty = Object.entries(groupedData).reduce(
        (max, [key, value]) => {
            if (value.length > max.length) {
                return { name: key, length: value.length };
            }
            return max;
        },
        { name: null, length: 0 }
    );

    return maxProperty;
};
  
export function groupDataByMonth(data) {
    const groupedByMonth = {};
  
    for (const zone in data) {
      data[zone].forEach((entry) => {
        const month = entry.OrderMonthName; // Use the month name as the key
        if (!groupedByMonth[month]) {
          groupedByMonth[month] = []; // Initialize the month key if not present
        }
        groupedByMonth[month].push({
          ZoneName: entry.ZoneName,
          ZoneId: entry.ZoneId,
          UniqueDoctors: entry.UniqueDoctors,
          MedicineCount: entry.MedicineCount,
          TotalPapValue: entry.TotalPapValue,
          MedID: entry.MedID,
          MedicineName: entry.MedicineName,
          TotalPatients: entry.TotalPatients,
          OrderYear: entry.OrderYear,
        });
      });
    }
  
    return groupedByMonth;
  }
  
  export function orderDataByMonth(data) {    
    const monthOrder = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const orderedData = {};
    monthOrder.forEach((month) => {
      if (data[month]) {
        orderedData[month] = data[month];
      }
    });
  
    return orderedData;
  }



  export function calculateMonthlyTotals(data) {
    const monthlyTotals = {};

    for (const month in data) {
        let totalMedicineCount = 0;
        let totalPapValue = 0;
        let totalPatients = 0;        
        
        const zones = data[month];
        zones.forEach(zone => {
            totalMedicineCount += zone.MedicineCount || 0;
            totalPapValue += zone.TotalPapValue || 0;
            totalPatients += zone.TotalPatients || 0;            
        });

        monthlyTotals[month] = {
            totalMedicineCount,
            totalPapValue,
            totalPatients            
        };
    }

    return monthlyTotals;
}

export const getMonthRange = (startIndex, endIndex) => {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
    // Validate and adjust the indices
    if (startIndex < 0 || endIndex > 11 || startIndex > endIndex) {
      return 'Invalid month range';
    }
  
    return monthNames.slice(startIndex, endIndex + 1);
  };


  export const getMinMaxOrderMonth = (data) => {
    const months = data.map(item => item.OrderMonth);
    const minMonth = Math.min(...months);
    const maxMonth = Math.max(...months);
    return { minMonth, maxMonth };
  };
  
  export const sortDataMonthOnMonth = (data) => {
    // For each doctor ID group, sort by OrderMonth
    for (const doctorId in data) {
        // Sort by OrderMonth (ascending)
        data[doctorId].sort((a, b) => a.OrderMonth - b.OrderMonth);
    }

    return data;
};
  

export function getTotalsByMonth(data) {
    const result = {};

    // Iterate over each zone in the data
    for (const zone in data) {
        data[zone].forEach((entry) => {
            const monthKey = entry.OrderMonthName; // Use OrderMonthName as the key

            // Initialize the monthKey in the result if not already present
            if (!result[monthKey]) {
                result[monthKey] = {
                    TotalPapValue: 0,
                    MedicineCount: 0,
                    TotalPatients: 0,
                };
            }

            // Aggregate the values
            result[monthKey].TotalPapValue += entry.TotalPapValue;
            result[monthKey].MedicineCount += entry.MedicineCount;
            result[monthKey].TotalPatients += entry.TotalPatients;
        });
    }

    return result;
}