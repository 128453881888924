import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { generateColorArray } from '../../Service/Common';
import './BrandLineChart.css';
import { ChartBackground } from '../../Service/AppConstants';

const BrandLineChart = (props) => {

    const [years, setYears] = React.useState([]);
    const [patients, setPatients] = React.useState([]);

    React.useEffect(() => {
        const fiscalYears = props.data.map(item => item.FiscalYear);
        const patients = props.data.map(item => item.TotalPatients);        
        setYears(fiscalYears);
        setPatients(patients);        
        return () => console.log('');
    }, [props.data]);

    const series = [{
        name: "Patients",
        data: patients //[...patients]
    }];

    const options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            events: {
                click: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
                    // console.log('Events:', event);
                    // console.log('chartContext:', chartContext);
                    // console.log('seriesIndex:', seriesIndex);
                    // console.log('dataPointIndex:', dataPointIndex);
                    // console.log('config:', config);
                }
            },
            background: ChartBackground,
            dropShadow: {
                enabled: true
            }, 
            padding: {
                top: 20,
                right: 20,
                bottom: 30,
                left: 40,
              },      
        },

        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 10,
            offsetY: -5,
            labels: {
                colors: '#fff',  // Change to any color you prefer
                useSeriesColors: false // Set to false unless you want the legend colors to match the series colors
            },
            fontSize: '12px',
            fontWeight: 400,
            markers: {
                //fillColors: ['red', 'blue', 'yellow'] // Optionally change marker colors if needed
            }
        },

        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            colors: '#feb019'
        },
        title: {
            text: 'Financial year Oncyclo brand patients',
            align: 'center',
            style: { fontWeight: 500, color: '#fff', fontSize: 12 },
            offsetY: 15
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
            xaxis: {
                lines: {
                    show: false // This will hide the x-axis lines across the chart
                }
            },
            yaxis: {
                lines: {
                    show: false // This will hide the y-axis lines across the chart
                }
            },
            row: {
                colors: ['transparent', 'transparent'],
                opacity: 0
            },
        },
        xaxis: {
            categories: years, //[...years],
            labels: {
                style: {
                    colors: [...generateColorArray(12, '#fff')],
                    fontSize: '12px',
                },
                offsetX: 0,
                offsetY: 0,
            }
        },
        yaxis: {
            title: {
                text: ''
            },
            tickAmount: 10,
            min: (Math.min(...patients)/2),
            max: (Math.max(...patients)*2),
            labels: {
                style: {
                    colors: ['#ffffff'],
                    fontSize: '12px',
                },                
                offsetX: 0, // Space between y-axis labels and the chart
                offsetY: 0,
                formatter: (value) => Math.round(value),
            },
        },
    };

    return (
        <>
            <div id="BrandLineChart">
                <ReactApexChart options={options} series={series} width={'100%'} height={400} />
            </div>
        </>
    );
};

export default BrandLineChart;
