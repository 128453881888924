import React from 'react';
import { useNavigate } from 'react-router-dom';

const ZonePerformanceByKamItem = ({ zoneId, zoneName, performer, numOfPatients, vials, pap }) => {

    const navigate = useNavigate();

    React.useEffect(() => {
    }, []);


    const zoneIndiaHandler = (zoneId) => {
        navigate(`/kam-dashboard?zoneName=${zoneName}&zoneId=${zoneId}`)
    };

    return (
        <div className="col-3" onClick={() => zoneIndiaHandler(zoneId)}>
            <div className={`top-performer-wrapper ${zoneName.toLowerCase()}`}>
                <b className={`heading ${zoneName.toLowerCase()}`}>{zoneName.charAt(0).toUpperCase() + zoneName.slice(1)}</b>
                <table className={`${zoneName.toLowerCase()}`}>
                    <tbody>
                        <tr>
                            <td>Kam</td>
                            <td>{performer ? performer : '----'}</td>
                        </tr>
                        <tr>
                            <td>Patients</td>
                            <td>{numOfPatients ? numOfPatients : '----'}</td>
                        </tr>
                        <tr>
                            <td>Vials</td>
                            <td>{vials ? vials : '----'}</td>
                        </tr>
                        <tr>
                            <td>Pap</td>
                            <td>{pap ? pap : '----'}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    );
};

export default React.memo(ZonePerformanceByKamItem);
